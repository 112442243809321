@import "./Components/variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", "SolaimanLipi", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $primary-color;
}

.App {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  display: flex;
  justify-content: center;
  font-family: "Roboto", "SolaimanLipi", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  &.btn {
    border-radius: 6px;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.75rem 1rem;
    cursor: pointer;
    color: white;
    border: none;
    background: none;
    background: $primary-color-dark1;
    position: relative;
    z-index: 1;
    &.medium {
      width: 100%;
      max-width: 10rem;
    }
    &:not(.clear)::before,
    &:not(.clear)::after {
      // content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 50%;
      left: 50%;
      border-radius: 2rem;
      transform-origin: center;
      transition: transform 0.1s cubic-bezier(0.44, 0.13, 0.54, 1.02);
    }
    &:not(.clear)::before {
      // background: red;
      transform: translate(-50%, -50%) scale(0.9);
      border: 2px solid $primary-color-dark1;
      z-index: -5;
    }
    &:not(.clear)::after {
      background: linear-gradient(
        45deg,
        $primary-color-dark1 0%,
        $primary-color-dark2 100%
      );
      transform: translate(-50%, -50%);
      border-radius: 2rem;
      z-index: -1;
    }
    &:not(.clear) {
      &:hover,
      &:active {
        &::before {
          transform: translate(-50%, -50%) scale(1.015, 1.1);
        }
        &::after {
          transform: translate(-50%, -50%) scale(0.98, 0.85);
        }
      }
      &:active {
        background: $primary-color-dark2;
      }
    }
    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
    &.secondary {
      background: white;
      color: $primary-color-dark1;
      outline: 2px solid $primary-color-dark1;
      outline-offset: -2px;
    }
  }
  &.clear {
    cursor: pointer;
    color: inherit;
    border: none;
    background: none;
    width: max-content;
    &.iconOnly {
      font-size: 1.75em;
      padding: 0.25rem 0.5rem;
      align-items: center;
    }
    &.border {
      border: 1px solid $grey2;
      border-radius: 4px;
    }
    &:disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  &.small {
    padding: 0.25rem;
  }
}

.btns {
  display: flex;
  justify-content: center;
  grid-gap: 0.5rem;
  grid-column: 1 / -1;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.loadingSpinner {
  font-size: 4rem;
  animation: spin 1.5s linear infinite;
  margin: auto;
  color: rgba(0, 0, 0, 0.75);
  position: absolute;
  inset: 0;
}
.spin {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.skl-loading {
  position: relative;
  overflow: hidden;
  display: inline-block;
  border: 1px solid rgba(black, 0.1);
  border-radius: 1em;
  background: rgba(black, 0.05);
  &::after {
    height: 300%;
    width: 80%;
    transform: translateY(-50%) rotate(-15deg);
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    bottom: 0;
    background: linear-gradient(
      90deg,
      rgba(black, 0) 0%,
      rgba(black, 0.1) 30%,
      rgba(black, 0.1) 70%,
      rgba(black, 0) 100%
    );
    animation: loading 2s cubic-bezier(0.12, 0.35, 0.39, 0.71) infinite;
  }
}

@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.grid {
  display: grid;
}
.flex {
  display: flex;
  &.wrap {
    flex-wrap: wrap;
  }
}
.flex-1 {
  flex: 1;
}

.gap_5 {
  grid-gap: 0.5rem;
}
.gap-1 {
  grid-gap: 1rem;
}
.gap-2 {
  grid-gap: 2rem;
}

.p_5 {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.pl-0 {
  padding-left: 0rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl_5 {
  padding-left: 0.5rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 1rem;
}
.pt_5 {
  padding-top: 0.5rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb_5 {
  padding-bottom: 0.5rem;
}
.pb-2 {
  padding-bottom: 2rem;
}

.m_5 {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-a {
  margin: auto;
}
.mr-0 {
  margin-right: 0;
}
.mr_5 {
  margin-right: 0.5rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.ml-0 {
  margin-left: 1rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml_5 {
  margin-left: 0.5rem;
}
.ml-2 {
  margin-left: 2rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 1rem;
}
.mt_5 {
  margin-top: 0.5rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-0 {
  margin-bottom: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb_5 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 2rem;
}

.subtitle1 {
  font-size: 14px;
  color: $grey2;
  font-style: italic;
}

.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-space-between {
  justify-content: space-between;
}
.align-start {
  align-items: flex-start;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}

.all-columns {
  grid-column: 1 / -1;
  flex-basis: 100%;
}

.underline {
  text-decoration: underline !important;
}

.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  -webkit-line-clamp: 2;
  &.l-1 {
    -webkit-line-clamp: 1;
  }
  &.l-2 {
    -webkit-line-clamp: 2;
  }
  &.l-3 {
    -webkit-line-clamp: 3;
  }
  &.l-4 {
    -webkit-line-clamp: 4;
  }
  &.l-5 {
    -webkit-line-clamp: 5;
  }
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}

.chips {
  display: flex;
  grid-gap: 5px;
  flex-wrap: wrap;
  .chip {
    padding-left: 0.5rem;
    border: 1px solid $primary-color;
    border-radius: 5px;
    display: flex;
    grid-gap: 5px;
    align-items: center;
    text-transform: capitalize;
    overflow: hidden;
    .clear {
      font-size: 1.2em;
      padding: 0.3rem;
      color: $primary-color-dark1;
      &:hover {
        color: white;
        background: $primary-color-dark1;
      }
    }
  }
}

.error {
  background: $red;
  color: white;
  padding: 0.5rem;
}

.manyItems {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  .icon {
    background: $primary-color-dark2;
    color: white;
    aspect-ratio: 1;
    width: 2em;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    display: grid;
    place-items: center;
    position: relative;
    .allItems {
      z-index: 20;
      text-align: left;
      display: none;
      position: absolute;
      background: white;
      color: black;
      width: max-content;
      padding: 0.5rem;
      right: -0.8rem;
      top: 2.5em;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.52));
      border-radius: 4px;
      grid-gap: 0.2em;
      &:hover {
        display: grid;
      }
      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 0.5rem solid transparent;
        border-top: 0;
        border-bottom: 0.7rem solid white;
        position: absolute;
        right: 1rem;
        top: -0.7rem;
      }
    }
    &:hover {
      .allItems {
        display: grid;
      }
    }
  }
}

.border-t {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.inlineForm {
  display: flex !important;
  td {
    width: 100%;
    display: flex;
    grid-template-columns: inherit;
    grid-gap: inherit;
    form {
      width: inherit;
      display: grid;
      grid-template-columns: inherit;
      grid-gap: inherit;
      .btns {
        align-items: center;
        grid-column: -1 / -2;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}
