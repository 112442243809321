@import "./Components/variables";

#portal,
#prompt {
  position: fixed;
  height: max-content;
  width: max-content;
  top: 0;
  left: 0;
  z-index: 100;
  display: grid;
  place-items: center;
  margin: auto;
  pointer-events: none;
  max-height: calc((var(--vh) * 100));
  & > * {
    pointer-events: all;
    grid-column: 1/2;
    grid-row: 1/2;
  }
}

.modalBackdrop {
  height: calc((var(--vh) * 100));
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  pointer-events: all;
}
.modal {
  pointer-events: all;
  z-index: 10;
  background: white;
  max-height: calc((var(--vh) * 100) - 2rem);
  max-width: calc(100vw - 2rem);
  & ~ .modalBackdrop {
    z-index: 10;
    & ~ .modal {
      z-index: 10;
    }
  }
  position: relative;
  border-radius: 0.5rem;
  display: grid;
  overflow: auto;
  top: auto;
  left: auto;
  height: max-content;
  width: max-content;
  &.withHead {
    overflow: hidden;
    & > div:nth-child(2) {
      overflow: auto;
      max-height: calc((var(--vh) * 100) - (3rem + 36px));
      // max-width: calc(100vw - 3rem);
    }
  }
  .head {
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    font-weight: 500;
    padding: 2px 10px;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 0.5rem;
      font-size: 1.75rem;
    }
  }
}

.promptBackdrop {
  height: calc((var(--vh) * 100));
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
}
.prompt {
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  padding: 5px 2rem;
  padding-bottom: 2rem;
  border-radius: 0.5rem;
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 25rem;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 2rem;
    color: #4f4f4f;
  }
  .content {
    display: grid;
    grid-gap: 1.3rem;
    .label {
      display: grid;
      justify-content: center;
      margin-bottom: -1rem;
      .svg {
        font-size: 3.75rem;
        font-weight: bold;
      }
      .promptLabel {
        font-size: 1.5rem;
        line-height: 0.5em;
      }
      &.confirmation {
        color: rgb(254, 183, 0);
      }
      &.information {
        color: $blue;
      }
      &.success {
        color: $green;
      }
      &._error {
        color: $red;
      }
    }
    p {
      overflow-wrap: anywhere;
    }
    .actions {
      justify-content: center;
      display: flex;
      grid-gap: 0.7rem;
      .no {
        filter: saturate(0);
        opacity: 0.7;
      }
    }
  }
}

.reactSelect {
  width: 100%;
  // &:focus-within {
  //   border-color: $primary-color;
  // }
  // &.err {
  //   border-color: red;
  // }
  // &.reactSelect--is-disabled {
  //   background: rgb(235, 235, 235);
  // }
  // border: 1px solid #b7b7b7;
  // border-radius: 4px;
  .reactSelect__control {
    display: flex;
    height: min-content;
    background: white;
    border-radius: 4px;
    .reactSelect__value-container {
      padding: 5px 7px;
    }
    .reactSelect__single-value,
    .reactSelect__input-container {
      align-items: center;
      max-height: 24px;
      padding: 0 !important;
      margin: 0;
      grid-column: 1/2;
      grid-row: 1/2;
      font-size: 13.5px !important;
      color: black !important;
      min-width: 4rem;
    }
    .reactSelect__indicators {
      .reactSelect__indicator {
        color: rgba(0, 0, 0, 0.8);
        padding: 6px;
      }
    }
    .reactSelect__placeholder {
      font-size: 13px;
    }
    .reactSelect__indicator-separator {
      display: none;
    }
    .reactSelect__menu {
      .react-select__menu-list {
        box-shadow: none;
        .react-select__option {
          padding: 10px;
        }
      }
    }
  }
  &.hideMultipleValue {
    .reactSelect__clear-indicator,
    .reactSelect__multi-value {
      display: none;
    }
  }
  &.readOnly {
    pointer-events: none;
    .reactSelect__control {
      background: #f5f4f4;
    }
  }
}

.rdrDateRangePickerWrapper {
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrDateDisplayWrapper {
    .rdrDateDisplayItemActive {
      color: $primary-color;
    }
  }
  .rdrDays {
    button {
      z-index: 1;
      .rdrStartEdge,
      .rdrInRange,
      .rdrEndEdge {
        color: $primary-color-dark1 !important;
      }
      .rdrDayStartPreview,
      .rdrDayInPreview,
      .rdrDayEndPreview {
        border-color: $primary-color-dark1 !important;
      }
      &.rdrDayToday {
        span::after {
          background: $primary-color-dark2;
        }
      }
    }
  }
  &.multiple {
    .rdrDays {
      button {
        .rdrStartEdge,
        .rdrInRange,
        .rdrEndEdge {
          color: $orange !important;
        }
        .rdrDayStartPreview,
        .rdrDayInPreview,
        .rdrDayEndPreview {
          border-color: $orange !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .modal {
    max-height: calc((var(--vh) * 100));
    max-width: 100vw;
    &.withHead {
      & > div:nth-child(2) {
        overflow: auto;
        max-height: calc((var(--vh) * 100 - 44px));
        max-width: 100vw;
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba($primary-color, 0.2);
    border-radius: 1rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 1rem;
  }
}
