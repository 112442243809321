$primary-color: #8fa9ff;
$primary-color-dark1: #608de0;
$primary-color-dark2: #385da3;
$primary-color-dark3: #1b386e;

$white: #fff;
$grey1: rgb(236, 236, 236);
$grey2: rgb(158, 158, 158);
$grey3: rgb(57, 57, 57);

$comp-color: #eb4f39;
$comp-color-dark1: #be3f2e;
$comp-color-dark2: #9d3021;
$comp-color-dark3: #6c2319;

$green: #68ad03;
$red: #c00208;
$orange: #fb8433;
$yellow: #f6d304;
$blue: #0473d6;

$comp-grey: #f2f2f2;

$shadow1: 0 0 10px rgba(0, 0, 0, 0.15);
